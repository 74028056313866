import React from 'react';

import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';
import { Link } from '~/src/components/Link';
import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import {
  IntegrateScreenSection,
  IntegrateScreenIntegrationsTitle,
  IntegrateScreenIntegrations,
  IntegrateScreenButton,
} from './styles/IntegrateScreen.styles';

export const IntegrateScreen = () => (
  <IntegrateScreenSection data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>Integrate in a heartbeat, no matter your stack</SolutionsSubHeading>
      <SolutionsDescription>
        Uploadcare is stack-agnostic, with support for most programming languages and even no-code
        platforms, including Shopify, Marketo, Zapier and Make. Integrate once, customize every part
        of your workflow, and enjoy a lifetime of maintenance-free growth for your marketplace
        business.
      </SolutionsDescription>

      <IntegrateScreenButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_1"
      >
        Start building for free
      </IntegrateScreenButton>
    </Container>

    <IntegrateScreenIntegrationsTitle>
      Dozens of ready integrations
    </IntegrateScreenIntegrationsTitle>
    <IntegrateScreenIntegrations />
  </IntegrateScreenSection>
);
