import styled from 'styled-components';
import { Code } from '@uc-common/code';

export const StatusBadge = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #65717b;
  padding: 4px 8px;
  background: #0c0d0e;
  border: 1px solid #263e7c;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.16),
    0 4px 18px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  position: relative;
  margin-bottom: 43px;

  &::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    color: transparent;
    background: #29f593;
    margin-right: 6px;
  }
`;

export const Container = styled.div`
  height: 348px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    border: 1px solid transparent;
    background-clip: content-box;
    background-image: linear-gradient(
      180deg,
      rgba(53, 95, 194, 0) 0%,
      #3660c4 30.63%,
      #6945c1 50%,
      rgba(105, 69, 193, 0.44) 100%
    );
  }
`;

export const CodeContainer = styled.div`
  border: 1px solid transparent;
  position: relative;
  display: inline-block;
  border-radius: 12px;
  background:
    linear-gradient(#000, #000) padding-box,
    linear-gradient(
        180deg,
        rgba(105, 69, 192, 0.71) 0%,
        rgba(105, 69, 192, 0.26) 48.96%,
        rgba(105, 69, 192, 0.62) 100%
      )
      border-box;
`;

export const StyledCode = styled(Code)`
  --background-color: #000;
  --default-color: #b392f0;
  --color-1: #69e3bf;
  padding: 14px 20px;

  code {
    padding: 0;
    border: none;
  }

  .operator {
    color: #49535d;
  }
`;
