import React from 'react';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  FirstScreen,
  AdvantageScreen,
  ServicesScreen,
  IntegrateScreen,
  PlatformFeaturesScreen,
  LastScreen,
} from '../../components/forMarketplaces';

export const frontmatter = {
  title: 'Uploadcare marketplace solutions',
  description:
    'Convert more buyers and sellers with natively responsive product images that load ultra-fast. Upload, manage, deliver all UGC photos with a single pipeline.',
};

const Marketplaces = ({ location }) => (
  <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
    <FirstScreen />
    <AdvantageScreen />
    <ServicesScreen />
    <IntegrateScreen />
    <PlatformFeaturesScreen />
    <LastScreen />
  </SiteLayout>
);

export default Marketplaces;
