import React from 'react';

import { Container } from '@uc-common/container';

import { PlanCardFree } from '../../PlanCardFree';
import { LastScreenSection } from './styles/LastScreen.styles';

export const LastScreen = () => (
  <LastScreenSection data-navbar-theme="dark">
    <Container>
      <PlanCardFree btnSignUpProps={{ 'data-analytics': 'btn_signup_2' }} />
    </Container>
  </LastScreenSection>
);
