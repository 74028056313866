export const data = [
  {
    title: 'Modern image delivery',
    text: 'Accept all UGC product images. Convert and deliver on the fly between JPG and PNG to modern WEBP, AVIF, and HEIC formats',
  },
  {
    title: '14 file upload sources',
    text: 'Native uploads from phone, PC, Google Drive, Meta, Dropbox and more. Integrate with Facebook and Instagram Marketplace.',
  },
  {
    title: 'Mobile-first images',
    text: 'Automate image size, format, quality and srcset. Fit images to listing design with smart focus point detection.',
  },
  {
    title: 'Detect & moderate content',
    text: 'Categorize images with AI, moderate unsafe content, blur faces and edit image metadata with automated workflows.',
  },
  {
    title: 'Smart image compression',
    text: 'Reduce image size by >80% with the highest image quality. Make listings load faster. Progressive & lazy loading also available.',
  },
  {
    title: 'Remove BG & add overlays',
    text: 'Easily add white backgrounds, overlays and watermarks to improve your listings and boost marketplace conversion rates.',
  },
];
