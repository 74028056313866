import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { SmallCard, Quote } from '~/src/components/forSolutions';
import { Section } from '../../forMain/Section';
import {
  AdvantageScreenDescription,
  AdvantageScreenCardsContainer,
} from './styles/AdvantageScreen.styles';

export const AdvantageScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>
        Turn sellers’ and buyers’ photos into competitive advantage
      </SolutionsSubHeading>
      <AdvantageScreenDescription>
        Scale your marketplace’s UGC photos and user experience with minimal custom work.
      </AdvantageScreenDescription>
      <AdvantageScreenCardsContainer>
        <SmallCard
          title="File uploader + image handling APIs"
          src="https://ucarecdn.com/0e928b99-e406-4f5e-9be4-edb80fe78ee5/"
        />
        <SmallCard
          title="Safe storage with NSFW content filtering"
          src="https://ucarecdn.com/8f9fcd00-0021-4e1f-ae4e-50c6b2a10aef/"
        />
        <SmallCard
          title="Automated image editing and optimizations"
          src="https://ucarecdn.com/ee401467-f80b-4227-b80e-df1f383cb336/"
        />
        <SmallCard
          title="Global CDN with leading image compression"
          src="https://ucarecdn.com/b62b40c2-f8ba-4e10-a6cf-2e7771315bc7/"
        />
      </AdvantageScreenCardsContainer>
    </Container>
    <Quote
      logoSrc={logos[BuzzLogoIdEnum.SNUGGPRO_BW].src}
      logoAlt="Snugg pro logo"
      quoteText="Uploadcare allowed us to get photo upload and cropping into our app within hours. More importantly, they are super responsive with support. We've been running this in production for months and our users love it."
      avatarSrc="https://ucarecdn.com/0e179599-2f9a-44ab-b088-ec507873a109/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Jeff Friesen | CTO"
    />
  </Section>
);
