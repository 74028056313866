export const SERVICES = [
  {
    superHeading: 'File Uploader',
    heading: 'Help buyers and sellers upload quality product images',
    paragraph:
      'Secure product photos that sell with our leading file uploader. It’s mobile-friendly and a breeze for your users, with built-in image editor, drag-and-drop, sorting, filetype validation, and 14 file sources.',
    options: [
      'Modern looks & experience',
      'Built-in image editor',
      'Upload multiple images',
      'Any file type (up to 5TB)',
    ],
    imageUuid: '99e8696c-cd6e-48c1-9f72-981d3d16f792',
    imageAlt: 'Widget image editor',
  },
  {
    superHeading: 'Cloud storage & URL API',
    heading: 'Store files, process images, moderate content',
    paragraph:
      'Manage UGC product photos in bulk from one place: image tagging, object detection, explicit and unsafe content detection, and dynamic image transformations with a handy URL API.',
    options: [
      'Secure cloud storage & malware protection',
      'Blur faces, add watermarks',
      'Scale crop, smart crop, auto crop images',
      'Add white background',
    ],
    imageUuid: '7e258e50-8962-46ad-8e02-d67e6cc437ae',
    imageOptions: { preview: '777x532', quality: 'smart', format: 'auto' },
    imageAlt: 'Widget image editor',
  },
  {
    superHeading: 'Image CDN & URL API',
    heading: 'Deliver smooth online shopping and load speeds',
    paragraph:
      'Accelerate your marketplace with worldwide image delivery. Our image CDN compresses images to the smallest possible format, saving up to 86% bandwidth while keeping your listing photos natively SEO- and mobile-friendly.',
    options: [
      '99.99% SLA: no downtime',
      'Auto quality, srcset & scaling',
      'HEIC, WEBP, AVIF support',
      'Progressive & lazy loading',
    ],
    imageUuid: 'cc8581a5-7301-42b6-a991-4971d1ebb859',
    imageOptions: { preview: '798x532', quality: 'smart', format: 'auto' },
    imageAlt: 'Widget image editor',
  },
];
