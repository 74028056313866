import React from 'react';
import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { Quote, FeatureCard, IntegrationSection } from '~/src/components/forSolutions';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import {
  IntegrationLogo,
  IntegrationSectionArrowButton,
  IntegrationSectionArrowButtonText,
  IntegrationSectionArrowIcon,
  IntegrationSectionParagraph,
  IntegrationSectionTitle,
} from '~/src/components/forSolutions/IntegrationSection';
import SSGLogo from '../assets/SSGLogo.svg';
import {
  PlatformFeaturesScreenSection,
  PlatformFeaturesScreenGradientSuperHeading,
  PlatformFeaturesScreenFeaturesGrid,
  PlatformFeaturesScreenApiExample,
} from './styles/PlatformFeaturesScreen.styles';
import { data } from './data';

export const PlatformFeaturesScreen = () => (
  <PlatformFeaturesScreenSection data-navbar-theme="dark">
    <PlatformFeaturesScreenApiExample />

    <Container>
      <PlatformFeaturesScreenGradientSuperHeading>
        Uploadcare Platform features
      </PlatformFeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>
        Boost marketplace conversions and spend less time processing images
      </SolutionsSubHeading>
    </Container>
    <IntegrationSection>
      <IntegrationLogo src={logos[BuzzLogoIdEnum.MIGHTYCAUSE_BW].src} alt="Mightycause logo" />
      <IntegrationSectionTitle>
        Attracting more P2P donations with visually captivating images and videos
      </IntegrationSectionTitle>
      <IntegrationSectionParagraph>
        Learn how the leading peer-to-peer fundraising marketplace for nonprofits streamlined
        operations and drastically improved user experience by including our image and video
        uploading to their fundraising pages. All without “losing sleep at night” over scalability
        and traffic spikes.
      </IntegrationSectionParagraph>
      <IntegrationSectionArrowButton to="/customers/mightycause/" dataAnalytics="btn_mightycause">
        <IntegrationSectionArrowButtonText>Read more</IntegrationSectionArrowButtonText>
        <IntegrationSectionArrowIcon />
      </IntegrationSectionArrowButton>
    </IntegrationSection>
    <Container>
      <PlatformFeaturesScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </PlatformFeaturesScreenFeaturesGrid>
    </Container>

    <Quote
      logoSrc={SSGLogo}
      logoAlt="Special Situations Group logo"
      quoteText="The 'return on investment' of Uploadcare is
      self-evident from day one, and that's just from the time savings.
      I can think of no reason why anyone should reinvent the wheel or even
      attempt to roll out their own solution from scratch. Uploadcare has
      brought file uploading, processing, and delivery to perfection."
      avatarSrc="https://ucarecdn.com/f5bcbf71-40ae-4d88-9d13-4257699b4d7e/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Terence Hong | CEO"
    />
  </PlatformFeaturesScreenSection>
);
