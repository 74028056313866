import React from 'react';
import { CodeContainer, Container, StatusBadge, StyledCode } from './ApiExample.styles';

export const ApiExample = () => (
  <Container>
    <StatusBadge>200</StatusBadge>
    <CodeContainer>
      <StyledCode
        copyButton={{ isEnabled: false }}
        language="json"
        code={`{
  "event": "file.uploaded",
  "uuid": "564v...sgj6",
}`}
      />
    </CodeContainer>
  </Container>
);
